<div class="backdrop">
  <div class="dialog-card" [ngClass]="classes">
    <wlx-heading>
      <wlx-heading-lead x2 *ngIf="title">
        {{ title }}
      </wlx-heading-lead>
      <wlx-heading-subdivision x3 *ngIf="message">
        <br />
        <span class="dialog-card-message">
          {{ message }}
        </span>
      </wlx-heading-subdivision>
    </wlx-heading>
    <ng-content *ngTemplateOutlet="content"></ng-content>
    <ng-container *ngIf="closeButtonVisible">
      <br />
      <wlx-button (click)="dialogService.close()">{{ closeButtonLabel }}</wlx-button>
    </ng-container>
  </div>
</div>
