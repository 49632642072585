import { ComponentRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public viewContainerRef?: ViewContainerRef;
  private dialog?: ComponentRef<DialogComponent>;
  private backlog = new Array<{ content?: TemplateRef<any>; title?: string; message?: string }>();

  open(
    content?: TemplateRef<any>,
    title?: string,
    message?: any,
    timeout?: number,
    closeButton?: boolean,
    closeButtonLabel?: string,
    classes: string[] = ['dialog-card']
  ) {
    if (this.isOpen()) {
      this.backlog.push({ content, title, message });
      this.close();
      return;
    }
    console.log('open dialog');
    this.dialog = this.viewContainerRef?.createComponent(DialogComponent);
    if (!this.dialog) return;
    if (content) {
      this.dialog.instance.content = content;
    } else {
      this.dialog.instance.title = title || '';
      this.dialog.instance.message = message || '';
      this.dialog.instance.closeButtonVisible = closeButton || false;
      this.dialog.instance.closeButtonLabel = closeButtonLabel || '';
    }

    this.dialog.instance.classes = classes;

    if (timeout) {
      timer(timeout).subscribe(() => {
        this.close();
      });
    }

    // const view = this.viewContainerRef?.createEmbeddedView(content);
    //  this.viewContainerRef.a
  }

  isOpen(): boolean {
    return !!this.dialog;
  }

  close() {
    if (!this.dialog) return;
    this.dialog.location.nativeElement.classList.add('closing');
    this.dialog.location.nativeElement.addEventListener('animationend', () => {
      this.dialog?.destroy();
      this.dialog = undefined;
      if (this.backlog.length > 0) {
        const entry = this.backlog.shift();
        this.open(entry.content, entry.title, entry.message);
      }
    });
  }

  showErrorDialog(title: string, message: any) {
    this.open(undefined, title, message);
    setTimeout(() => {
      this.close();
    }, 1000 * 5);
  }
}
