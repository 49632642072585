import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from './dialog.service';

@Component({
  selector: 'wlx-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @ViewChild('content')
  content?: TemplateRef<any>;

  title?: string;
  message?: any;
  classes?: string[];
  closeButtonVisible = false;
  closeButtonLabel = '';

  constructor(public dialogService: DialogService) {}
}
